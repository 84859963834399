<template>
  <main id="passwordreset-view">
    <div class="card__header">
      <div class="card__header-text">
        <h4 class="card__title">{{ $t('passwordreset') }}</h4>
      </div>
    </div>
    <template v-if="resetPasswordSent">
      <div class="card card--with-header">
        <div class="card__content">
            <p>{{ $t('password-changed') }}</p>
        </div>
      </div>
    </template>
    <template v-else>
    <div class="card card--with-header">
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('passwordreset_subtitle') }}</h4>
        </div>
        <form action="#signin" class="form" @submit="onSubmit">
          <form-field v-for="item in formFields" :key="item.name" :ref="item.name" :fielddata="item" v-on:change="onFieldChange"></form-field>
        </form>
        <div class="modal__actions">
          <div class="modal__actions--left">
            &nbsp;
          </div>
          <div class="modal__actions--right">
            <button type="button" class="btn btn--new" @click="onSubmit">{{ $t('submit') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__actions">
      <div class="modal__actions--left">
        &nbsp;
      </div>
      <div class="modal__actions--right">
        <router-link to="/signin" class="btn btn--new-secondary">{{ $t('signin') }}</router-link>
      </div>
    </div>
    </template>
  </main>
</template>
<script>
import {
  mapActions
} from 'vuex'
import FormField from '@/core/components/FormField.vue'
import {
  CHANGE_PASSWORD,
  SHOW_NOTIFICATION
} from '@/core/action-types'

export default {
  name: 'signin',
  components: {
    FormField
  },
  data: function () {
    return {
      resetPasswordSent: false,
      formValues: {
        token: '',
        email: '',
        password_confirmation: '',
        password: ''
      }
    }
  },
  computed: {
    formFields: function () {
      return [
        {
          name: 'password',
          type: 'password',
          label: `${this.$t('password')} *`,
          title: this.$t('password'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w25'
        },
        {
          name: 'password_confirmation',
          type: 'password',
          label: `${this.$t('password-confirm')} *`,
          title: this.$t('password-confirm'),
          value: '',
          required: true,
          maxlength: 50,
          pattern: null,
          class: '--w25'
        }
      ]
    }
  },
  mounted () {
    this.getLocationParams()
  },
  methods: {
    ...mapActions({
      changePassword: CHANGE_PASSWORD,
      showNotification: SHOW_NOTIFICATION
    }),
    getLocationParams () {
      let params = window.location.search.substr(1).split(('&'))

      for (let i = 0; i < params.length; i++) {
        let param = params[i].split('=')
        this.formValues[param[0]] = decodeURIComponent(param[1])
      }
    },
    onFieldChange (data) {
      this.formValues[data.field] = data.value
    },
    onSubmit (e) {
      const passwordField = this.$refs['password'][0].$el.firstChild
      const passwordConfirmField = this.$refs['password_confirmation'][0].$el.firstChild

      if (this.formValues.password.length < 8) {
        passwordField.setCustomValidity(this.$t('error-password-length'))
        passwordField.reportValidity()
      } else if (!/[a-z]/.test(this.formValues.password)) {
        passwordField.setCustomValidity(this.$t('error-password-lowercase'))
        passwordField.reportValidity()
      } else if (!/[A-Z]/.test(this.formValues.password)) {
        passwordField.setCustomValidity(this.$t('error-password-uppercase'))
        passwordField.reportValidity()
      } else if (!/[\d]/.test(this.formValues.password)) {
        passwordField.setCustomValidity(this.$t('error-password-number'))
        passwordField.reportValidity()
      } else if (!/[\W]/.test(this.formValues.password)) {
        passwordField.setCustomValidity(this.$t('error-password-special-char'))
        passwordField.reportValidity()
      } else if (this.formValues.password !== this.formValues.password_confirmation) {
        passwordConfirmField.setCustomValidity(this.$t('error-password-donesnt-match'))
        passwordConfirmField.reportValidity()
      } else {
        const onComplete = function (responseData) {
          if (responseData.data?.success) {
            this.resetPasswordSent = true
            window.setTimeout(() => {
              this.$router.push({ path: '/signin' })
            }, 5000)
          } else {
            this.showNotification({ text: responseData.error.message, type: 'error', icon: 'error' })
          }
        }
        passwordConfirmField.setCustomValidity('')
        this.changePassword({
          token: this.formValues.token,
          email: this.formValues.email,
          password: this.formValues.password,
          password_confirmation: this.formValues.password_confirmation,
          onComplete: onComplete.bind(this)
        })
      }
      e.preventDefault()
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
#passwordreset-view {
  margin: 0 auto;
  width: 600px;
}
.card {
  display: block;
}
.card__content {
  padding: 20px;
}
.form {
  padding: 10px;
}
.form__field {
  flex-basis: calc(50% - 12px);
}
</style>
